// extracted by mini-css-extract-plugin
export var customServiceModal = "style-mod--custom-service-modal--2pIwV";
export var modalClose = "style-mod--modal-close--2YweY";
export var modalCloseDesktop = "style-mod--modal-close-desktop--IaEVN";
export var modalCloseDesktopDesktop = "style-mod--modal-close-desktop-desktop--3uOoM";
export var modalCloseDesktopResponsive = "style-mod--modal-close-desktop-responsive--3gv7R";
export var modalCloseResponsive = "style-mod--modal-close-responsive--1ya4p";
export var modalParagraph = "style-mod--modal-paragraph---p4oX";
export var modalTitle = "style-mod--modal-title--2v3xG";
export var grindingServiceModal = "style-mod--grinding-service-modal--3wUaL";
export var ccBody = "style-mod--cc-body--1GN3t";
export var grindingServiceImage = "style-mod--grinding-service-image--37LCX";
export var grindingServiceHead = "style-mod--grinding-service-head--L02Rr";
export var grindingServiceContent = "style-mod--grinding-service-content--2xyGB";
export var grindingServiceAccordionTitle = "style-mod--grinding-service-accordion-title--3GwhH";
export var grindingServiceAccordion = "style-mod--grinding-service-accordion--1x-3M";
export var accordionCard = "style-mod--accordion-card--1I-yG";
export var cardHeader = "style-mod--card-header--ROePj";
export var accordionIcon = "style-mod--accordion-icon--3O5Gw";
export var accordionTitle = "style-mod--accordion-title--3oIUt";
export var accordionDescription = "style-mod--accordion-description--kcA0b";
export var accordionList = "style-mod--accordion-list--QPkMf";
export var accordion = "style-mod--accordion--26NGA";