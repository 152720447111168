import React, { useState } from "react";
import { Modal,  Accordion, Card } from "react-bootstrap";
import CloseIcon from "assets/svgs/close-btn.svg";

import {
  grindingService, grindingServiceModal,  grindingServiceHead, grindingServiceAccordion,
  modalClose, grindingServiceImage, modalCloseResponsive, ccBody,
  accordionCard, cardHeader, grindingServiceContent, grindingServiceAccordionTitle,
  accordionTitle, accordion, accordionDescription, customServiceModal,
  accordionIcon, accordionList, modalCloseDesktop, modalContainer
} from "./style.mod.scss";
import {  ChevronDown, ChevronUp } from "react-bootstrap-icons";
import RichText from "components/rich-text";

const GrindingService = ({ data, children }) => {
  const [show, setShow] = useState(false);
  const [open, setOpen] = useState({});
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const mData = data?.modal && data.modal[0];
  if (!data || !mData) return <></>;

  return (
    <div className={grindingService}>
      <div className={modalContainer} onClick={handleShow}>
        { children }
      </div>

      <Modal show={show} onHide={handleClose} className={`${customServiceModal} ${grindingServiceModal}`}>
        <CloseIcon className={`${modalClose} ${modalCloseDesktop}`} onClick={handleClose} />
        <Modal.Body className={ccBody}>
          <CloseIcon className={`${modalClose} ${modalCloseResponsive}`} onClick={handleClose} />
          <div className={grindingServiceHead}>
            <RichText
              blocks={mData.description}
            />
          </div>
          <div className={grindingServiceContent}>
            <div className={grindingServiceAccordion}>
              <p className={grindingServiceAccordionTitle}>So funktionierts:</p>
              <ul>
                {
                  mData?.accordion?.map((ac)=>(
                    <li key={ac._key}>
                      <span className={accordionList}>{ac.id}</span>
                      <Accordion className={`${accordion} pointer`}  key={ac.id}  onClick={() => setOpen({
                        ...open, [ac.id]: !open[ac.id]
                      })}>
                        <Card className={accordionCard}>
                          <Accordion.Toggle as={Card.Header} className={cardHeader} eventKey="0" onClick={() => setOpen(!open)}>
                            <p className={accordionTitle}>{ac.title}</p>
                            <span className={accordionIcon}>{open[ac.id] == true ? <ChevronUp /> : <ChevronDown />}</span>
                          </Accordion.Toggle>
                          <Accordion.Collapse eventKey="0">
                            <Card.Body className={accordionDescription}>
                              <RichText
                                blocks={ac?.accordionText}
                              />
                            </Card.Body>
                          </Accordion.Collapse>
                        </Card>
                      </Accordion>
                    </li>
                  ))
                }
              </ul>
            </div>
          </div>
        </Modal.Body>
        <img className={grindingServiceImage} src="https://imgur.com/ifFhWMS.png"></img>
      </Modal>
    </div>
  );
};

export default GrindingService;
